<template>
  <div>
    <FavorContainer
      v-if="flag('stage_6_datingFinish') && flag('stage_6_datingBonus') == false"
      @close="datingBonus"
      :stage="stage"
      :step="1"
    ></FavorContainer>
    <div class="position-relative full-page dating-bg">
      <div class="dating-title">約會高手是誰</div>
      <div class="result-list text-center">
        <ul class="list-unstyled rank-list font-14">
          <li
            class="d-flex align-items-center"
            :class="{ winner: index == 0 }"
            v-for="(row, index) in score_list"
            :key="row.user.id"
          >
            <div class="mr-2">
              <span>{{ index + 1 }}</span>
            </div>
            <div class="cha-circle circle mr-3">
              <img v-bind:src="showAvatar(row.user.avatar)" alt="" />
            </div>
            <div class="team-name text-truncate mr-3 text-left">{{ row.character.name }}- {{ row.user.nickname }}</div>
            <div>
              <div>score:{{ row.score }}</div>
            </div>
          </li>
        </ul>
        <div class="mt-3">
          <a role="button" class="btn btn-primary btn-block mb-3 text-white" @click="finishDating">結束逛街</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "../Mixins/Avatar";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import { sendMessage } from "../utils/WebSocket";
import UserFromVuex from "../Mixins/UserFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import FavorContainer from "./favor/FavorContainer";
import FavorFromMixin from "../Mixins/FavorFromMixin";

export default {
  name: "danceStandBy",
  mixins: [Avatar, TeamFromVuex, UserFromVuex, StageFromMixin, WebSocketMixin, FavorFromMixin],
  components: {
    // fullpageInfo,
    // resultList
    FavorContainer
  },
  computed: {
    score_list() {
      return Object.values(this.team_payload?.datingScore).sort((a, b) => b.score - a.score);
    },
    winner() {
      return this.score_list?.[0];
    }
  },
  data() {
    return {
      stage: {
        stage_id: 6
      }
    };
  },
  methods: {
    datingBonus() {
      this.setFlag({ key: "stage_6_datingBonus", status: true });
      this.setFlag({ key: "stage_6_datingWinner", status: this.winner.character.name });

      const data = {
        method: "EnterStage",
        token: this.token,
        location: {
          link: "ximen"
        }
      };
      sendMessage(data);
    },
    finishDating: function() {
      if (this.flag("stage_6_datingFinish")) {
        this.$router.replace("ximen");
      }
      this.setWinner({
        winner: this.winner.character,
        user: this.winner.user,
        stage: this.stage
      });
      this.addScoreByRate({
        score: 30,
        characterName: this.winner.character?.name,
        isMoveStage: false,
        hint_key: "date"
      });
      this.setFlag({ key: "stage_6_datingFinish", status: true });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.dating-title {
  background: rgba(27, 167, 233, 0.8);
  color: #fff;
  font-size: 16px;
  padding: 96px 0 14px;
  text-align: center;
}

.result-list {
  padding: 30px 30px 0;
  li.winner {
    // background: #ffbc3c;
    // color: #fff;
    > div:first-child {
      span {
        display: block;
        transform: translateX(-5px) rotate(10deg) scale(3);
        font-weight: bold;
      }
    }
  }
}
</style>
