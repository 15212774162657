<template>
  <div class="position-relative full-page dating-bg overflow-hidden">
    <a
      v-if="!checkMotionEvent"
      @click="po"
      class="position-fixed page-btn d-flex justify-content-center align-items-center"
    >
      開始遊戲
    </a>
    <a
      v-if="isDatingEnd"
      @click="leave"
      class="position-fixed page-btn d-flex justify-content-center align-items-center"
    >
      結束約會
    </a>
    <div class="score-block text-center">
      Score: {{ score }}
      <div class="steps d-flex justify-content-between">
        <div class="step-point" v-for="n in questionCount" :key="n" :class="{ active: n <= currentQuestion }"></div>
        <div class="steps-progress">
          <div class="steps-progress-bar" :style="{ width: datingProgress }"></div>
        </div>
      </div>
    </div>
    <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
      <div
        class="mask mask-dark d-flex justify-content-center align-items-center dating-mask"
        v-if="datingPrepare"
        @click="datingPrepare = false"
      >
        <img src="/img/dataing-prepare.png" alt="" />
      </div>
    </transition>

    <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
      <div class="dating-map animated" v-if="showMap">
        <a role="button" class="map-close-btn position-fixed" @click="showMap = false"
          ><img src="img/icon-close.svg" alt=""
        /></a>

        <datingMap></datingMap>
      </div>
    </transition>
    <!--    <FavorContainer-->
    <!--      v-if="flag('stage_6_datingFinish') && flag('stage_6_datingBonus') == false"-->
    <!--      @close="datingFinish"-->
    <!--      :stage="stage"-->
    <!--      :step="1"-->
    <!--    ></FavorContainer>-->
    <talkingDating :talkingList="talkingList"></talkingDating>

    <div class="test-location position-absolute">
      <!--      平均: {{ (testWalkStep) }}-->
      目前步數: {{ shakeCount }}
    </div>
    <div class="card-timer position-absolute"
         @click="()=>{flag('touchable') && walk()}">{{ timerCount }}s</div>
    <div class="card-location position-absolute">
      <a @click="showMap = true">
        <img src="/img/icon-dating-location.svg" alt="" />
      </a>
    </div>
    <!-- <div class="card-info position-absolute">
      <a>
        <img src="/img/icon-dating-info.svg" alt=""/>
      </a>
    </div> -->
    <div class="card-list position-absolute">
      <div
        class="card-hand"
        v-for="cardContent in cardList"
        :key="cardContent.id"
        @click="chooseCard(cardContent)"
        :class="{ active: cardContent.id == cardId, throw: cardContent.id == cardThrow }"
      >
        <div
          class="card-hand-throw position-absolute"
          v-if="cardContent.id == cardId && canSend"
          @click="sendCard(cardContent)"
        >
          <img src="/img/hand-card.svg" alt="" />
        </div>
        <div class="card-content">{{ cardContent.content }}</div>
        <img @click="chooseCard" :src="'img/' + card" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import scoreBoard from "@/views/part/scoreBoard";
import talkingDating from "@/views/part/talkingDating";
import datingMap from "@/views/part/datingMap";

import CharacterFromVuex from "@/Mixins/CharacterFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import Avatar from "@/Mixins/Avatar";
import UserFromVuex from "../Mixins/UserFromVuex";

import { mapActions, mapGetters } from "vuex";
import { power, setDeviceMotionEvent } from "../utils/Motion";
import debounce from "lodash.debounce";
import StageFromMixin from "../Mixins/StageFromMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import FavorFromMixin from "../Mixins/FavorFromMixin";
import throttle from "lodash.throttle";
import HowlAudio from "@/Mixins/HowlAudio";
import {getCurrentPosition} from "../libs/getCurrentPosition";

export default {
  name: "dating",
  components: {
    // scoreBoard,
    talkingDating,
    datingMap
  },
  mixins: [
    Avatar,
    CharacterFromVuex,
    WebSocketMixin,
    UserFromVuex,
    StageFromMixin,
    TeamFromVuex,
    FavorFromMixin,
    HowlAudio
  ],
  computed: {
    ...mapGetters("Character", ["getRoleByName"]),
    ...mapGetters("Dating", [
      "questions",
      "talkingContent",
      "answers",
      "maxShakeCount",
      "event_lock",
      "talkingList",
      "cardList"
    ]),
    usedCard() {
      return this.localFlag("usedCard");
    },
    cardList: function() {
      let card = this.cards.map(d => d);
      return this.answers.filter(d => card.some(item => item == d.id));
    },
    talking: function() {
      let shakeCount = Math.max(this.shakeCount, this.maxShakeCount);
      console.log("shakeCount: ", shakeCount);
      // console.log("shakeCount", shakeCount);
      return this.talkingContent
        .filter(function(content) {
          // console.log("check ", content.stepCount, "| show: ",content.show )
          return shakeCount >= content.stepCount && content.show == false;
        })
        .sort((a, b) => a.id - b.id)?.[0];
    },
    datingProgress() {
      let percent = Math.round((this.currentQuestion / (this.questionCount - 1)) * 100) + "%";
      return percent;
    },

    isDatingEnd() {
      return this.flag('stage_6_datingFinish') || this.maxShakeCount > 503;
    }
  },
  watch: {
    maxShakeCount(val, oldVal) {
      if (val != oldVal) {
        this.stepCheck();
      }
    }
    // talkingList: function(val, oldVal) {
    //   if (val.length == oldVal.length) return;
    //
    //   let newQuestId = 0;
    //   console.log("val length: ",val.length, "| oldVal length: ",oldVal.length);
    //   for(let i = oldVal.length-1; i < val.length; i++)
    //   {
    //     let talk = val[i];
    //     if(talk.class == "question-bubble"){
    //       newQuestId++;
    //     }
    //   }
    //   console.log("aft: ",this.currentQuestion);
    //  this.currentQuestion = (this.currentQuestion < newQuestId)?newQuestId :this.currentQuestion;
    //   console.log("bef: ",this.currentQuestion);
    // }
  },
  data() {
    return {
      stage: {
        stage_id: 6
      },
      foot_size: 1,
      stepTimer: null,
      timer: null,
      countdownAudio: null,
      messageAudio: null,
      timerCount: 30,
      shakeCount: 0,
      score: 0,
      card: "",
      cardId: "",
      cards: ["A3", "A18", "A11"],
      datingPrepare: false,
      canSend: false,

      // usedCard: [3, 18, 11],
      talkingIndex: 0,
      talkingCount: 0,
      qIndex: 0,
      cardThrow: "",
      answerCount: 0,
      showMap: false,
      questionCount: 11,
      currentQuestion: 0,
      checkMotionEvent: false,
      walkDistance: 0,
      tempWalkStep: 0,
      testWalkStep: 0,
      openFootStep: false
    };
  },
  mounted: function() {
    this.$nextTick(function() {
      this.foot_size = 5;
      // end TODO
      this.card = this.character.card;
      // console.log(this.character.card)
      this.answerCount = this.answers.length;
      this.talkingCount = this.talkingContent.length;
      console.log("check talkingCount: ", this.talkingCount);
      console.log("check talking 2: ", this.talking);
      this.talkAdd();
      this.stepTimer = setInterval(this.stepCheck, 100);
      this.getCard();
      this.getCard();
      this.openFootStep = true;
      this.syncForDisconnect();
      console.log("check question : ", this.currentQuestion, "|", this.questionCount);
      console.log("check score : ", this.score);
    });
    // console.log(this.cardList)
    this.countdownAudio = this.makeAudio({
      src: "/audio/card-countdown.mp3",
      loop: true
    });
    this.messageAudio = this.makeAudio("/audio/msg-sound.mp3");
    this.countdownAudio.pause();
    this.$once("hook:beforeDestroy", function() {
      this?.countdownAudio?.unload();
      this?.messageAudio?.unload();
      this.stepTimer && clearInterval(this.stepTimer);
      this.timer && clearInterval(this.timer);
    });
  },
  created() {
    this.updateUserCenter();
  },
  methods: {
    ...mapActions("Dating", [
      "updateMaxShakeCount",
      "speakTalkingContent",
      "reset",
      "set_event_lock",
      "setTalked",
      "pushTalkingList",
      "pushQuestionToTalkingList",
      "pushScore"
    ]),

    po: function() {
      this.checkMotionEvent = true;
      setDeviceMotionEvent(this.footStep, console.log);
      // setDeviceMotionEvent(this.checkFight, console.log);
    },
    syncForDisconnect: function() {
      this.shakeCount = this.maxShakeCount;
      this.progressSync();
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    updateUserCenter() {
      if (navigator.geolocation) {
        getCurrentPosition(
          position => {
            let p = position.coords;
            // 將中心點設為目前的位置
            this.userCenter = [p.latitude, p.longitude];
            // console.log(this.map_config.center)
          },
          err => {
            alert(err.message);
          }
        );
      } else {
        alert("您的瀏覽器不支援地理定位");
      }
    },
    datingFinish() {
      this.setFlag("stage_6_datingBonus", { status: true });
    },
    walk() {
      if (this.canSend || this.timerCount < 30) {
        return;
      }
      this.shakeCount = this.shakeCount + this.foot_size;
      this.updateMaxShakeCount({ step: this.shakeCount });
      this.stepCheck();
    },
    footStep: function(event) {
      // let debounceTest = debounce(function(){
      //   console.log("here in debounce!!!!!");
      // }, 100);

      // debounceTest();
      if (!this.openFootStep) return;
      if (this.event_lock) return;
      if (this.canSend || this.timerCount < 30) return;

      let currWalkStep =
        Math.abs(event?.acceleration?.x) + Math.abs(event?.acceleration?.y) + Math.abs(event?.acceleration?.z);
      this.testWalkStep = currWalkStep > 2 ? currWalkStep : this.testWalkStep;
      this.tempWalkStep = currWalkStep > 2 ? this.tempWalkStep + currWalkStep : this.tempWalkStep;

      this.addWalkDistance(currWalkStep);
      if (this.walkDistance > 0) {
        console.log("in~~~~~~~~~");
        this.addOnlineStep();
      }
    },
    addWalkDistance: throttle(function(currWalkStep) {
      if (this.tempWalkStep > 10) {
        // this.testWalkStep = this.tempWalkStep / 800;
        console.log("check currWalkStep: ", currWalkStep);
        this.walkDistance++;
        console.log("check openFootStep: ", this.openFootStep, "| ");
        console.log("add step: ", this.tempWalkStep, "|", this.walkDistance);
      }
    }, 200),
    addOnlineStep: async function() {
      this.openFootStep = false;
      this.walkDistance = 0;
      this.tempWalkStep = 0;
      this.shakeCount = this.shakeCount + 1;
      console.log("check shakeCount", this.shakeCount);
      this.updateMaxShakeCount({ step: this.shakeCount });
      console.log("start wait!!!!!!!!");
      await this.wait(1000, this.stepCheck);
      console.log("end wait!!!!!!!!!!");
      this.openFootStep = true;
    },
    wait: async function(ms, fn, ...args) {
      fn(...args);
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    beginStepRecord() {
      if (typeof DeviceMotionEvent.requestPermission === "function") {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === "granted") {
              window.addEventListener("devicemotion", this.footStep);
            }
          })
          .catch(console.error);
      } else {
        // handle regular non iOS 13+ devices
        window.addEventListener("devicemotion", this.footStep);
      }
    },

    timerCountdown: function() {
      this.timerCount--;
      if (this.timerCount <= 0) {
        clearInterval(this.timer);
        this.timerCount = 30;
        this.canSend = false;
        this.countdownAudio.stop();
        this.stepTimer = setInterval(this.stepCheck, 100);
        // this.countdownAudio = null;
      }
    },
    getCard: function() {
      if (this.cards.length >= 5) return;
      let gotCard = Math.floor(Math.random() * 32) + 1;
      if (this.usedCard.includes(gotCard)) {
        this.getCard();
        return;
      }
      let cardId = "A" + gotCard.toString();
      if (this.cards.includes(cardId)) {
        this.getCard();
        return;
      }

      this.cards.push("A" + gotCard);
    },
    stepCheck: function() {

      this.talkAdd();
      if (this.cardList.length < 5) {
        this.getCard();
      }
    },
    pushTalking: function(character, talkingData) {
      if (Array.isArray(talkingData?.content)) {
        talkingData.content.forEach(d => {
          let character = this.getRoleByName(d.character);
          let content = {
            character: character?.name,
            picture: character?.picture,
            class: "",
            content: d.content
          };
          this.messageAudio.play();
          this.pushTalkingList(content);
          //TODO 增加等待時間
        });
      } else if (character) {
        let content = {
          character: character?.name,
          picture: character?.picture,
          class: talkingData?.class,
          content: talkingData.content
        };
        this.messageAudio.play();
        this.pushTalkingList(content);
      }

      this.setTalked(talkingData);
      // this.speakTalkingContent(this.talkingIndex)
    },
    progressSync: function() {
      if (this.talkingList.length <= 0) {
        return;
      }

      this.talkingList.forEach(talk => {
        if (talk.class == "talk-me" && talk.character == this.character.name) {
          this.score += talk.score;
        } else if (talk.class == "question-bubble") {
          console.log("check currentQuestion: ", talk.content);
          this.currentQuestion += 1;
        }
      });
    },
    talkAdd: function() {
      if (!this.talking) {
        return;
      }
      let talkingData = this.talking;
      console.log("~~~~~~talking: ", this.talking);
      let character = this.getRoleByName(talkingData?.character);
      if (talkingData.question != "") {
        talkingData.class = "question-bubble";
      }
      this.pushTalking(character, talkingData);

      if (talkingData.question != "" && !this.isDatingEnd) {
        this.currentQuestion = talkingData.question;
        this.set_event_lock(true);
        this.qIndex = talkingData.question;
        this.canSend = true;
        clearInterval(this.stepTimer);
        this.datingPrepare = true;
        this.timer = setInterval(this.timerCountdown, 1000);

        this.countdownAudio.play();
      }

      this.talkingIndex++;
      if (this.talkingIndex >= this.talkingContent.length) {
        clearInterval(this.stepTimer);
      }
    },
    chooseCard: function(cardContent) {
      if (cardContent.id == this.cardId) {
        this.cardId = "";
        return;
      }
      this.cardId = cardContent.id;
    },
    pushQuestion: function(found, score) {
      let question = {
        character: this.character.name,
        picture: this.character.picture,
        class: "talk-me",
        content: found["content"],
        score: score
      };
      this.pushQuestionToTalkingList(question);
    },
    pushUsedCard(id) {
      let usedCard = this.usedCard;
      usedCard.push(id);
      this.setLocalFlag({
        key: "usedCard",
        status: usedCard
      });
    },
    sendCard: function(cardContent) {
      let found = this.cardList.find(element => element.id == this.cardId);
      let score = found["Q" + this.qIndex];
      this.pushUsedCard(cardContent.id);
      this.pushQuestion(found, score);
      this.score = this.score + score;
      this.pushScore({
        score: this.score,
        character: this.character,
        user: this.user
      });
      this.messageAudio.play();

      this.cardThrow = cardContent.id;
      this.canSend = false;

      this.cards = this.cards.filter(d => d != cardContent.id);

      // clearInterval(this.timer);
      setTimeout(() => {
        //補牌
        if (this.cards.length < 5) {
          this.getCard();
        }
        // this.stepTimer = setInterval(this.stepCheck, 100);
        this.cardThrow = "";
        this.shakeCount = this.maxShakeCount;
      }, 2000);

      // this.timerCount = 15;
    },
    endGameAfter1S: throttle(() => {
      this.showModal("請確認所有人都在線上才能繼續遊戲");
    }),
    endGame() {
      if (!this.allMemberOnline) {
        this.endGameAfter1S();
        return;
      }
      if (this.flag("stage_6_datingFinish") == false) {
        this.setFlag("stage_6_datingFinish", { status: true });
        this.$router.replace("datingResult");
      }
    },
    leave() {
      if (this.flag("stage_6_datingFinish") == false) {
        this.setFlag("stage_6_datingFinish", { status: true });
      }
      this.$router.replace("datingResult");
    }
  }
  // beforeDestroy () {
  //   this?.countdownAudio?.unload();
  //   this.stepTimer && clearInterval(this.stepTimer);
  //   this.timer && clearInterval(this.timer);
  // }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

$dating-color: #ed2231;

.dating-mask {
  img {
    width: 75%;
  }
}

.map-close-btn {
  right: 20px;
  top: 20px;
  z-index: 999;
  display: block;

  img {
    width: 20px;
    height: 20px;
  }

  :active {
    transform: scale(0.8);
  }
}

.dating-map {
  overflow: hidden;
  padding: 20px;
  position: fixed;
  left: 10px;
  top: 10px;
  right: 10px;
  height: 80vh;
  background: #fff;
  z-index: 100;
  border-radius: 10px;
  @include default-shadow;
}

@keyframes throwCard {
  30% {
    bottom: 200px;
    /*left: 50%;*/
    transform: scale(1.5);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: 200px;
    /*left: 50%;*/
    transform: scale(1.5);
  }
}

.score-block {
  background: #fff;
  color: $dating-color;
  border-bottom: 1px solid $dating-color;
  padding-top: 18px;
}

.talking-area {
  top: 80px;
  bottom: 180px;
  left: 0;
  right: 0;
}

@keyframes cardThrowAnimation {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(-10px);
  }
}

.card-list {
  left: -5vw;
  bottom: 0;

  .card-hand {
    position: absolute;
    height: 42.9vw;
    width: 30vw;
    bottom: -10vw;
    cursor: pointer;
    transition: 0.2s;

    .card-hand-throw {
      text-align: center;
      bottom: 100%;

      img {
        width: 60%;
        animation: cardThrowAnimation 1s 30;
      }
    }

    .card-content {
      position: absolute;
      right: 10px;
      top: 20px;
      bottom: 40px;
      left: 20px;
    }

    &.active {
      z-index: 10;
      bottom: 0;

      &.throw {
        animation: 2s throwCard;
      }
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        left: 10vw * $i;
      }
    }
  }
}

.speech-bubble {
  @include default-shadow;
}

.card-timer {
  color: $dating-color;
  font-size: 24px;
  font-weight: bold;
  right: 36px;
  bottom: 130px;
}

.test-location {
  right: 36px;
}

.card-location {
  background: $dating-color;
  padding: 0px 42px 0px 16px;
  border-radius: 20px 0 0 20px;
  right: 0;
  bottom: 81px;
  height: 42px;
  display: flex;
  align-items: center;
}

.card-info {
  right: 42px;
  bottom: 34px;
}

.steps {
  position: relative;
  /*margin-top: 10px;*/
  margin: 10px 25px 0;

  .steps-progress {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #adadad;
    top: 50%;
    transform: translateY(-50%);

    .steps-progress-bar {
      height: 100%;
      background: red;
      width: 0%;
    }
  }
}

.step-point {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #adadad;
  z-index: 5;

  &.active {
    background: red;
  }
}
</style>
