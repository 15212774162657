<template>
  <div class="position-relative">
    <miniMap :map_config="map_config" :flyTo="[25.0437, 121.5062]" :markers="markers"></miniMap>
    <div class="suggest-path">建議路線</div>
  </div>
</template>

<script>
import miniMap from "@/views/part/miniMap";
import { icon, latLngBounds } from "leaflet";

export default {
  name: "datingMap",
  components: {
    miniMap
  },
  computed: {
    markers() {
      return [
        {
          is: "lPolyline",
          options: {
            "lat-lngs": this.ximenRoute.polyline.latlngs,
            color: this.ximenRoute.polyline.color
          }
        },
        {
          is: "lMarker",
          options: {
            "lat-lng": this.ximenRoute.polyline.latlngs[0],
            icon: icon(this.ximenRoute.start.icon)
          }
        },
        {
          is: "lMarker",
          options: {
            "lat-lng": this.ximenRoute.pins.location[0],
            icon: icon(this.ximenRoute.pin1.icon)
          }
        },
        {
          is: "lMarker",
          options: {
            "lat-lng": this.ximenRoute.pins.location[1],
            icon: icon(this.ximenRoute.pin2.icon)
          }
        },
        {
          is: "lMarker",
          options: {
            "lat-lng": this.ximenRoute.pins.location[2],
            icon: icon(this.ximenRoute.pin3.icon)
          }
        },
        {
          is: "lMarker",
          options: {
            "lat-lng": this.ximenRoute.pins.location[3],
            icon: icon(this.ximenRoute.pin4.icon)
          }
        },
        {
          is: "lMarker",
          options: {
            "lat-lng": this.ximenRoute.pins.location[4],
            icon: icon(this.ximenRoute.pin5.icon)
          }
        },
        {
          is: "lMarker",
          options: {
            "lat-lng": this.ximenRoute.range.location,
            icon: icon(this.ximenRoute.range.icon)
          }
        }
        // {
        //   is: "lMarker",
        //   options: {
        //     "lat-lng": this.ximenRoute.polyline.latlngs[4],
        //     icon: icon(this.ximenRoute.end.icon)
        //   }
        // }
      ];
    }
  },
  data() {
    return {
      updateUserCenterTimeout: null,
      userCenter: [25.0436, 121.5139],
      user_location: {
        icon: icon({
          iconUrl: "/img/location.svg",
          iconSize: [100, 100],
          iconAnchor: [50, 50]
        })
      },
      map_config: {
        zoom: 17,
        minZoom: 15,
        maxZoom: 18,
        bounds: latLngBounds([
          [25.059609, 121.500229],
          [25.021118, 121.529439]
        ]),
        maxBounds: latLngBounds([
          [25.059609, 121.500229],
          [25.021118, 121.529439]
        ]),
        options: {
          zoomSnap: 0.5
        }
      },
      url: "https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png",

      ximenRoute: {
        start: {
          icon: {
            iconUrl: "/img/ximen-start.png",
            iconSize: [39, 71],
            iconAnchor: [18, 71]
          }
        },
        pin1: {
          icon: {
            iconUrl: "/img/ximen-pin-1.png",
            iconSize: [39, 71],
            iconAnchor: [18, 71]
          }
        },
        pin2: {
          icon: {
            iconUrl: "/img/ximen-pin-2.png",
            iconSize: [39, 71],
            iconAnchor: [18, 71]
          }
        },
        pin3: {
          icon: {
            iconUrl: "/img/ximen-pin-3.png",
            iconSize: [39, 71],
            iconAnchor: [18, 71]
          }
        },
        pin4: {
          icon: {
            iconUrl: "/img/ximen-pin-4.png",
            iconSize: [39, 71],
            iconAnchor: [18, 71]
          }
        },
        pin5: {
          icon: {
            iconUrl: "/img/ximen-pin-5.png",
            iconSize: [39, 71],
            iconAnchor: [18, 71]
          }
        },
        range: {
          icon: {
            iconUrl: "/img/ximen-range.png",
            iconSize: [71, 71],
            iconAnchor: [35, 0]
          },
          location: [25.0437, 121.5062]
        },
        end: {
          icon: {
            iconUrl: "/img/ximen-end.png",
            iconSize: [26, 42],
            iconAnchor: [13, 42]
          }
        },
        polyline: {
          latlngs: [
            [25.04293, 121.507765],
            [25.043365, 121.507854],
            [25.043953, 121.505091],
            [25.045074, 121.5054],
            [25.045262, 121.504614]
          ],
          color: "#d9006c"
        },
        pins: {
          location: [
            [25.04325, 121.5083], // 1 海派
            [25.0434, 121.5078], //2 阿宗麵線
            [25.0437, 121.5062], //3 萬年
            [25.0451, 121.5055], //4 誠品
            [25.045262, 121.504614] //5 日新戲院
          ]
        }
      }
    };
  }
};
</script>

<style scoped lang="scss">
.suggest-path {
  width: 200px;
  padding: 4px 12px;
  font-size: 2rem;
  font-weight: bold;
  background-color: #1ba7e9;
  color: #fff;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  border-radius: 18px;
  text-align: center;
}
</style>
