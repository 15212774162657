<template>
  <div class="talking-area position-absolute">
    <div class="talking-block position-absolute" ref="container" id="datingTalkingContainer">
      <ul class="list-unstyled" ref="talkingList">
        <li
          class="d-flex position-relative"
          v-for="(talk, index) in talkingList"
          v-bind:class="[who(talk)]"
          :key="index"
        >
          <div class="cha-circle circle cha-circle-s mt-2">
            <img class="cover-fit" v-bind:src="showAvatar(talk.picture)" alt="" />
          </div>
          <div>
            <div class="taking-character">
              {{ talk.character }}
              
              <div v-if="talk.class=='talk-me'" class="talk-score" :class="[scoreClass(talk.score)]">
                <img v-if="talk.score >= 0" src="/img/icon-talking-plus.svg" alt="" />
                <img v-else src="/img/icon-talking-minus.svg" alt="" />
                {{ scoreShow(talk.score) }}
              </div>
            </div>
            
            <div class="speech-bubble">
              <div v-html="talk.content"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import UserFromVuex from "../../Mixins/UserFromVuex";

export default {
  name: "talkingDating",
  mixins: [Avatar, UserFromVuex],
  props: {
    talkingList: {
      default: []
    }
  },
  methods: {
    who(talk) {
      // console.log('kghjklkjhghj',talk)
      if (talk.class == "question-bubble") {
        return "question-bubble";
      }
      // console.log(talk.class)
      return talk?.character == this?.character?.name ? "talk-me" : "";
    },
    scoreShow(score) {
      if (score >= 0) {
        return "+" + score;
      }
      return score;
    },
    scoreClass(score) {
      if (score >= 0) {
        return "talk-score talk-score-plus";
      }else{
        return "talk-score talk-score-minus";
      }
    }
  },
  watch: {
    talkingList: function() {
      // if (val.length == oldVal.length) return;
      this.$nextTick(() => {
        this.$refs.container.scrollTop = this.$refs.container.scrollHeight;
      });
    }
  }
};
</script>

<style lang="scss">
.speech-bubble {
  span.highlight {
    color: #ed2231;
  }
}

.talk-score{
  display: inline-block;
  border-radius: 15px;
  background-color: white;
  padding: 1px 30px 1px 2px;
  font-size: 12px;
  height: 100%;
  position: absolute;
  left: 120%;
  top: 0;
  // box-shadow: 2px 2px 3px black;
  white-space: nowrap;
}
.talk-score img{
  height: 100%;
}
.talk-me .talk-score{
  left: unset;
  right: 120%;
}

</style>
