<template>
  <div class="position-relative full-page dating-rule-bg">
    <logoutBtn position="upper-left"></logoutBtn>
    <fullpageInfo></fullpageInfo>
    <div class="panel-back"></div>
    <rule :rules="rules"></rule>
  </div>
</template>

<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import rule from "@/views/part/rule";
import logoutBtn from "@/views/part/logoutBtn";

export default {
  name: "datingRule",
  data() {
    return {
      rules: [
        {
          title: "<h1 class='text-center text-white'>我們約會吧！</h1>",
          content:
            "<p>什麼！小彥這個叛徒！居然跟小桃約會！讓我們展開去死去死團大作戰吧！😡<br/><br/>" +
            "接下來，我們就一起跟在小桃和小彥後方當超級電燈泡吧！😏<br/><br/>"
        },
        {
          title: "<h1 class='text-center text-white'></h1>",
          content:
            "<p>在他們西門町散步約會的過程中，小桃會不定時的對小彥問出關鍵問題，各位請趁小彥還在思考的時候，搶先答出自己心目中的正確答案、博得小桃歡心吧！讓小桃知道誰才是和她心有靈犀的那個人😘</p>"
        },
        {
          title: "<h3>Step1. 逛街約會吧</h3>",
          content:
            "<p>拿著手機，跟著小桃小彥的逛街路線在西門町徒步區內逛街約會！小桃將在逛街途中每隔50公尺左右的距離時，向玩家們<strong>提問關鍵問題</strong>，玩家們需在時限內打出答案手牌、回答小桃的問題</p><div class='text-center'><img src='/img/datingRule1.png' alt=''></div>"
        },
        {
          title: "<h3>【逛街路線在哪？】</h3>",
          content:
            "<p>點擊遊戲介面的右下角<strong>紅色地標icon</strong>，可以前往劇情中小桃小彥的逛街路線。搭配逛街路線行進，可以更符合對話內容情境、達到較為沉浸式的體驗<br/></p>" +
            "<div class='text-center'><img src='/img/datingRule2.png' alt=''></div>"
        },
        {
          title: "<h3>【自己逛也OK！】</h3>",
          content:
            "<p>若是徒步區行人眾多，也可自由安排其他路線、隨意行走，手機記錄到一定的<strong>行走步數</strong>後仍會觸發關鍵問題，不影響遊戲進行</p>"
        },
        {
          title: "<h3>Step2. 答案手牌</h3>",
          content: "<p>玩家手上固定會有五張答案手牌，由系統隨機分配，謹慎思考手牌的出牌時機吧</p>"
        },
        {
          title: "<h3>Step3. 說話的藝術</h3>",
          content:
            "<p>當玩家一回答完問題，<strong>立即會根據小桃喜好決定玩家得到多少約會積分</strong>。小桃會針對喜歡的回答給予加分，太過白目的回答可是會被扣分的。<br/><br/>" +
            "多說多錯，不如不說？NONONO！<strong>猶豫太久超過回答時限，視同放棄作答喔</strong>！在情場上保持緘默不是你的權利！</p>"
        },
        {
          title: "<h3>Step4. 獲勝條件</h3>",
          content:
            "<p>約會結束後，<strong>最高積分</strong>的玩家將成為情場高手獲得小桃的好感值！如果剛好同分的話就交由運氣，讓小桃自己看心情決定吧！</p>"
        },
        {
          component: "datingRuleVideo"
        },
        {
          component: "datingRuleEnd"
        }
      ]
    };
  },
  components: {
    fullpageInfo,
    rule,
    logoutBtn
  }
};
</script>

<style lang="scss">
.full-page.dating-rule-bg {
  background: url("/img/ximenBg.jpg");
  background-size: cover;
}
.rule-panel {
  h1 {
    padding-top: 30px;
    font-size: 35px;
  }

  p {
    color: #fff;
    font-size: 15px;
  }
  strong {
    color: #4285f4;
  }
  img {
    max-width: 220px;
  }
  span {
    color: #c64c72;
  }
}
</style>
